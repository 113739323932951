import jwtDecode from 'jwt-decode';
import React from 'react';
import { api_vistoriador } from '../apis/api';
import { Link } from 'react-router-dom';

class InicioPage extends React.Component {
    constructor(props) {
        super(props);
        console.log(2);
        
        let token = jwtDecode(localStorage.getItem('tokenvisto'));
       
        this.state = {
            id: token.id || 0,
            vistorias: [],
            imovel: [],
            status: {
                "Execução": [<div className="vistoria-status status-vistoria">Execução</div>, "status-vistoria"],
                "Aceito": [<div className="vistoria-status status-aprovado">Aceito</div>, "status-aprovado"],
                "Aberto": [<div className="vistoria-status status-aberto">Aberto</div>, "status-aberto"],
                "Controle de qualidade": [<div className="vistoria-status status-andamento">Controle de qualidade</div>, "status-andamento"],
                "Laudo negado": [<div className="vistoria-status status-negado">Laudo negado</div>, "status-negado"],
                "Laudo aprovado": [<div className="vistoria-status status-finalizado">Laudo aprovado</div>, "status-finalizado"],
                "Laudo pronto": [<div className="vistoria-status status-pronto">Laudo pronto</div>, "status-pronto"],
            },
            lastid: '',
            linkest: process.env.REACT_APP_APP_VISTORIA
        }

        this.getdata();
    }

    getdata = async () => {
        const { id } = this.state;
        let {data} = await api_vistoriador.post('/vistoriadores/getvistorias', {id});
        this.setState({
            vistorias: data['vistorias'],
            imovel: data['imovel'].data
        })
    }

    formatDia = (dateInput) => {
        if (!dateInput) return dateInput;
        const [dataInput, horaInput] = dateInput.split(' ');
        const [ano, mes, dia] = dataInput.split('-');
        return `${dia}/${mes}/${ano}`;
    }

    formatHora = (dateInput) => {
        if (!dateInput) return dateInput;
        const [hora, minuto] = dateInput.split(':');
        return `${hora}:${minuto}`;
    }

    filterImovel = (id, imovel) => {
        return imovel.filter( e => {
            return e.id == id
        })
    }

    openAccordion = (id) => {
        const {lastid} = this.state;

        if (lastid == id || lastid == ''){
            document.querySelector(`#${id}`).classList.toggle('show');
        }
        else{
            document.querySelector(`#${lastid}`).classList.remove('show');
            document.querySelector(`#${id}`).classList.toggle('show');
        }

        this.setState({lastid: id})
    }

    accpetvis = async (idx) => {
        const { id } = this.state;

        await api_vistoriador.post('/vistoriadores/acceptVis', {id: idx, vis: id})

        this.getdata()
    }

    refusevis = async (idx) => {
        const { id } = this.state;

        await api_vistoriador.post('/vistoriadores/refuseVis', {id: idx, vis: id})

        this.getdata()
    }

    render() {
        const {vistorias, imovel, status, linkest} = this.state;
        return (
            <>
                <link rel="stylesheet" href={require('../assets/css/Base/inicio.css')}/>

                <div class="content-accordions">
                    <div class="content-box-header">
                        <p>Próximas vistorias</p>
                    </div>
                    <div class="accordions">
                            <div class="accordion-item">
                                <label>
                                    <div className="colors-info">
                                        <div className="colors-info-item">
                                            <div className="status-dia status-aberto"></div>
                                            <p>Aberto</p>
                                        </div>
                                        <div className="colors-info-item">
                                            <div className="status-dia status-aprovado"></div>
                                            <p>Aceito</p>
                                        </div>
                                        <div className="colors-info-item">
                                            <div className="status-dia status-vistoria"></div>
                                            <p>Execução</p>
                                        </div>
                                        <div className="colors-info-item">
                                            <div className="status-dia status-andamento"></div>
                                            <p>Controle de qualidade</p>
                                        </div>
                                        <div className="colors-info-item">
                                            <div className="status-dia status-pronto"></div>
                                            <p>Laudo pronto</p>
                                        </div>
                                        <div className="colors-info-item">
                                            <div className="status-dia status-finalizado"></div>
                                            <p>Laudo aprovado</p>
                                        </div>
                                        <div className="colors-info-item">
                                            <div className="status-dia status-negado"></div>
                                            <p>Laudo negado</p>
                                        </div>
                                    </div>
                                </label>
                            </div> 
                        {
                            vistorias.map(e => (
                                <div class="accordion-item">
                                    <button class="accordion" onClick={k => {this.openAccordion('accordionC-' + e.id)}}>
                                        <div className="flex-n">

                                            <div className={`status-dia ${status[e.status][1]}`}></div>
                                            <p>
                                                {
                                                    this.filterImovel(e.imovel_id, imovel).map(i=>(
                                                        `${i.rua_imovel}, ${i.numero_imovel}`
                                                    ))
                                                }
                                            </p>
                                        </div>
                                        <i class="fas fa-sort-down"></i>
                                    </button>
                                    <div class="accordion-content" id={'accordionC-' + e.id}>
                                        <div class="accordion-content-item">
                                            <p>Endereço</p>
                                            <p>
                                                {
                                                    this.filterImovel(e.imovel_id, imovel).map(i=>(
                                                        `${i.rua_imovel}, ${i.numero_imovel}, ${i.complemento} - ${i.cidade}, ${i.estado}`
                                                    ))
                                                }
                                            </p>
                                        </div>
                                        <div class="accordion-content-item">
                                            <p>Metragem</p>
                                            <p>{this.filterImovel(e.imovel_id, imovel)[0].metragem || 0}m²</p>
                                        </div>
                                        <div class="accordion-content-item">
                                            <p>Data</p>
                                            <p>{this.formatDia(e.data_agendada)}</p>
                                        </div>
                                        <div class="accordion-content-item">
                                            <p>Horarío</p>
                                            <p>{this.formatHora(e.hora_agendada)}</p>
                                        </div>
                                        <h4>Retirada da chave</h4>
                                        <div class="accordion-content-item">
                                            <p>Endereço</p>
                                            <p>{e.endereco_retirada}</p>
                                        </div>
                                        <div class="accordion-content-item">
                                            <p>Contato</p>
                                            <p>{e.contato_retirada}</p>
                                        </div>
                                        {
                                            e.status == "Aberto" ? (
                                                <>
                                                    <div class="accordion-content-item">
                                                        <button className="choosebtns" onClick={p => {this.accpetvis(e.id)}}>Aceitar</button>
                                                        <button className="choosebtns negative" onClick={p => {this.refusevis(e.id)}}>Recusar</button>
                                                    </div>
                                                </>
                                            ) : (null)
                                        }
                                        {
                                            e.status == "Aceito" ? (
                                                <div class="accordion-content-item">
                                                    <a target="_blanck" href={`${linkest}/form/${e.HASH}`}>
                                                        <button className="choosebtns accepted">Iniciar</button>
                                                    </a>
                                                </div>
                                            ) : (null)
                                        }
                                    </div>
                                </div> 
                            ))
                        }   
                    </div>
                </div>
            </>
        )
    }
}

export default InicioPage