import React from 'react';

class FinaceiroPage extends React.Component {
    render() {
        return (
            <>  
                <link rel="stylesheet" href={require('../assets/css/Base/financeiro.css')}/>
                <div class="content-accordions">
                    <div class="content-box-header">
                        <p>financeiro</p>
                    </div>
                    <div class="accordions">
                        <div class="accordion-item">
                            <input type="radio" name="accordion" id="accordion-1" />
                            <label for="accordion-1" class="accordion-edit"><p>Conta bancária</p><div><a href=""><i class="far fa-edit"></i></a><i class="fas fa-sort-down"></i></div></label>
                            <div class="accordion-content">
                                <div class="accordion-content-item-finan">
                                    <i class="fas fa-university"></i>
                                    <p>Agência</p>
                                    <p>00001</p>
                                </div>
                                <div class="accordion-content-item-finan">
                                    <i class="far fa-credit-card"></i>
                                    <p>Conta</p>
                                    <p>121010100-1</p>
                                </div>
                            </div>
                        </div> 
                        <div class="accordion-item">
                            <input type="radio" name="accordion-extrato" id="accordion-extrato" />
                            <label for="accordion-extrato"><p>extrato</p><i class="fas fa-sort-down"></i></label>
                            <div class="accordion-content">
                                <div class="accordion-content-item-extrato">
                                    <div>
                                        <p>Vistoria</p>
                                        <p>Av. Higino Muzi Filho, 529 - Mirante, Marília - SP, 17525-901</p>
                                    </div>
                                    <p class="dinheiro">R$100,00</p>
                                </div>
                                <div class="accordion-content-item-extrato">
                                    <div>
                                        <p>Vistoria</p>
                                        <p>Av. Higino Muzi Filho, 529 - Mirante, Marília - SP, 17525-901</p>
                                    </div>
                                    <p class="dinheiro">R$100,00</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default FinaceiroPage