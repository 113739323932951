import React from "react";
import '../assets/css/Base/perfil.scss';
import jwt from 'jwt-decode';
import { api_admin } from '../apis/api';

class PerfilPage extends React.Component {
    constructor(props) {
        super(props);

        let token = localStorage.getItem('tokenvisto');
        token = jwt(token);

        this.state = {
            id: token.id,
            endereco: '',
            bairro: '',
            cidade: '',
            estado: '',
            numero: '',
            complemento: '',
            tel: '',
            cel: '',
            notaData: '',
            nota: 0,
            dataS: '',
            dataF: '',
            dataDiff: 0,
            dataInit: 0,
            dataFinal: 0,
            g1: 0,
            b1: 100,
            g2: 0,
            slider: false,
            imgPath: '',
            imgFile: '',
        }

        this.getData();
    }

    getData = async () => {
        let { data } = await api_admin.get("/vistoriadores/" + this.state.id)

        let n = 0, qt = 0;
        let dataS = '';
        let dataF = '';

        let dataX = '';
        let dataY = '';
        let dataD = '';

        try {
            dataS = new Date((data.nota[0].date).split(" ")[0]);
            dataF = new Date((data.nota[0].date).split(" ")[0]);

            dataX = (data.nota[0].date).split(" ")[0];
            dataY = (data.nota[0].date).split(" ")[0];

            // let dataF = (new Date()).toISOString().split("T")[0];

            for (let i of data.nota) {
                n += i.nota;
                qt += 1;

                if (dataS > new Date((i.date).split(" ")[0])) {
                    dataX = (i.date).split(" ")[0]
                    dataS = new Date((i.date).split(" ")[0])
                }

                if (dataF < new Date((i.date).split(" ")[0])) {
                    dataY = (i.date).split(" ")[0]
                    dataF = new Date((i.date).split(" ")[0])
                }
            }

            dataD = Math.ceil(Math.abs(dataF.getTime() - dataS.getTime()) / (1000 * 60 * 60 * 24));
        } catch (error) {

        }

        this.setState({
            endereco: data.user[0].endereco,
            bairro: data.user[0].bairro,
            cidade: data.user[0].cidade,
            estado: data.user[0].estado,
            numero: data.user[0].numero,
            complemento: data.user[0].complemento,
            tel: data.user[0].telefone,
            cel: data.user[0].celular,
            notaData: data.nota,
            nota: Math.round((n / qt) * 100) / 100 || 0,
            dataS: dataX,
            dataF: dataY,
            dataDiff: dataD,
            dataFinal: dataD,
            imgPath: data.user[0].img
        })
    }

    calcNote = async (ds, df) => {
        if (ds > df) {
            this.setState({
                dataFinal: parseInt(ds),
                dataInit: parseInt(df)
            })
        }
        else {
            this.setState({
                dataFinal: parseInt(df),
                dataInit: parseInt(ds)
            })
        }

        let s = new Date(this.state.dataS + " 00:00:00");
        let f = new Date(this.state.dataF + " 23:59:59");

        s.setDate(s.getDate() + parseInt(ds));
        f.setDate(f.getDate() - (this.state.dataDiff - parseInt(df)));

        let c = this.state.notaData.filter(r => new Date(r.date) >= s && new Date(r.date) <= f);

        let n = 0, qt = 0;

        for (let i of c) {
            n += i.nota;
            qt += 1;
        }

        let grayS1 = (ds * 100) / this.state.dataDiff
        let blueS1 = ((df - ds) * 100) / this.state.dataDiff + grayS1
        let grayS2 = ((this.state.dataDiff - df) * 100) / this.state.dataDiff
        console.log(grayS1);
        console.log(blueS1);
        console.log(grayS2);


        this.setState({
            nota: Math.round((n / qt) * 100) / 100 || 0,
            g1: grayS1,
            b1: blueS1,
            g2: grayS2,
        })
    }

    formatter = (d) => {
        d = d.split("-");

        return `${d[2]}/${d[1]}/${d[0]}`;
    }

    toggleSlider = async () => this.setState({ slider: !this.state.slider })

    setimg = async (e) => {
		let reader = new FileReader();
        let self = this;
        let img = e.target.files[0]
		this.setState({imgFile: e.target.files[0]})

		reader.onloadend = (ea) => {
            self.setState({imgPath: ea.target.result})
            
            let form = new FormData();
            form.append('id', self.state.id)
            form.append('imgFile', img);

            api_admin.post('/vistoriadores/editimg/', form)
		}

		reader.readAsDataURL(e.target.files[0]);
		
	}

    render() {
        return (
            <>
                {/* <link rel="stylesheet" href/> */}
                <div className="highPerfil">
                    <div className="componentPerf">
                        <img src="https://source.unsplash.com/random" alt="" />
                        <p>
                            {localStorage.getItem('nomevisto')} &nbsp;&nbsp;
                        </p>
                    </div>

                    <div className="DataPerfil">
                        <div className="dgr">
                            <div className="highData mln growMK2">
                                <span>Nota</span>
                                <div className="sameValue cataclis">
                                    <p>
                                        <i className="far fa-star"></i> &nbsp;{this.state.nota}
                                    </p>
                                    <p>
                                        <i className="far fa-calendar-alt" onClick={this.toggleSlider}></i>
                                    </p>
                                </div>
                            </div>
                            {
                                this.state.slider ? (
                                    this.state.dataF ? (
                                        <div className="highData mrl">
                                            <span>Nota</span>
                                            <div className="collective">
                                                <span>{this.formatter(this.state.dataS)}</span>
                                                <span>{this.formatter(this.state.dataF)}</span>
                                                <input type="range" max={this.state.dataDiff} min="0" onChange={a => this.calcNote(a.target.value, this.state.dataFinal)} value={this.state.dataInit} style={{ background: `linear-gradient(90deg, #f1f1f1 ${this.state.g1}%, ${this.state.g1}%, #91aeff ${this.state.b1}%, ${this.state.b1}%, #f1f1f1 ${this.state.g2}%)` }} />
                                                <input type="range" max={this.state.dataDiff} min="0" onChange={a => this.calcNote(this.state.dataInit, a.target.value)} value={this.state.dataFinal} />
                                            </div>
                                        </div>
                                    ) : (
                                            <div className="highData mrl">
                                                <br />
                                            Sem notas
                                            </div>
                                        )
                                ) : (<div className="highData mrl"></div>)
                            }
                        </div>
                        <div className="dgr">
                            <div className="highData mln mrl">
                                <span>Endereço</span>
                                <div className="sameValue">{this.state.endereco}, {this.state.numero}</div>
                            </div>
                        </div>
                        <div className="dgr">
                            <div className="highData mln">
                                <span>Bairro</span>
                                <div className="sameValue">{this.state.bairro}</div>
                            </div>
                            <div className="highData">
                                <span>Cidade</span>
                                <div className="sameValue">{this.state.cidade}</div>
                            </div>
                            <div className="highData mrl">
                                <span>Estado</span>
                                <div className="sameValue">{this.state.estado}</div>
                            </div>
                        </div>
                        <div className="dgr">
                            <div className="highData mln mrl">
                                <span>Complemento</span>
                                <div className="sameValue">{this.state.complemento}</div>
                            </div>
                        </div>
                        <div className="dgr">
                            <div className="highData mln">
                                <span>Telefone</span>
                                <div className="sameValue">{this.state.tel}</div>
                            </div>
                            <div className="highData mrl">
                                <span>Celular</span>
                                <div className="sameValue">{this.state.cel}</div>
                            </div>
                        </div>

                        <div className="dgr">
                            <div className="highData mln">
                                <label>
                                    <div className="divbtn">Adicionar assinatura</div>
                                    <input type="file" hidden onChange={this.setimg}/>
                                </label>
                            </div>
                        </div>

                        <div className="dgr">
                            <div className="highData mln">
                                <img src={this.state.imgPath} alt="" className="signed"/>
                            </div>
                        </div>


                        {/* <div className="dgr">
                            <div className="highData mln">
                                <span>Data inicial</span>
                                <input type="date" value={this.state.dataS} onChange={a => {this.calcNote(a.target.value, this.state.dataF)}}/>
                            </div>
                            <div className="highData mrl">
                                <span>Data Final</span>
                                <input type="date" value={this.state.dataF} onChange={a => {}}/>
                            </div>
                        </div> */}

                    </div>
                </div>
            </>
        )
    }
}

export default PerfilPage