import React from 'react';
import { api_vistoriador } from '../apis/api';
import jwtDecode from 'jwt-decode';


class ForgetPage extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            email: '',
        }
    }

    makeLogin = async (e) => {
        const {email} = this.state;
        e.preventDefault();
        console.log('====================================');
        console.log("SDS");
        console.log('====================================');

        let {data} = await api_vistoriador.post('/vistoriadores/forgetpass', {email});
        alert("Entre no seu email para continuar a com o processo")

        
    } 

    render() {
        return (
            <>
                <link rel="stylesheet" href={require('../assets/css/Base/login.css')}/>
                <link rel="stylesheet" href={require('../assets/css/Base/style.css')}/>
                <h1>RECUPERAÇÃO</h1>
                <form onSubmit={this.makeLogin}>
                    <div class="campo-login">
                        <label for="login-email">Email</label>
                        <div class="campo-login-input">
                            <i class="far fa-envelope"></i>
                            <input type="email" id="login-email" placeholder="exemplo@exemplo.com" onChange={e => {this.setState({email: e.target.value})}} required/>
                        </div>
                    </div>
                    <div class="campo-login-submit">
                        <input type="submit" value="Enviar"/>
                    </div>
                </form>
            </>
        )
    }
}

export default ForgetPage