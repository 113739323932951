import React from 'react';
import ReactDOM from 'react-dom';

import { Route, BrowserRouter, Switch } from 'react-router-dom';

import SignLayout from './layouts/sign'
import AdminLayout from './layouts/admin'

import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route path="/admin" render={props => <AdminLayout {...props}/>} />
            <Route path="/" render={props => <SignLayout {...props}/>} />
        </Switch>
    </BrowserRouter>, 
    document.getElementById('root'));

serviceWorker.unregister();
