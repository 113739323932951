import React from 'react';
import jwtDecode from 'jwt-decode';
import { api_vistoriador } from '../apis/api'

class AgendaPage extends React.Component {
    constructor(props) {
        super(props);

        let token = localStorage.getItem('tokenvisto');
        token = jwtDecode(token);

        this.state = {
            id: token.id,
            diasAgenda: [],
            classpop: 'popup-agenda hide',
            status: {
                "Execução": [<div className="vistoria-status status-vistoria">Execução</div>, "status-vistoria"],
                "Aceito": [<div className="vistoria-status status-aprovado">Aceito</div>, "status-aprovado"],
                "Aberto": [<div className="vistoria-status status-aberto">Aberto</div>, "status-aberto"],
                "Controle de qualidade": [<div className="vistoria-status status-andamento">Controle de qualidade</div>, "status-andamento"],
                "Laudo negado": [<div className="vistoria-status status-negado">Laudo negado</div>, "status-negado"],
                "Laudo aprovado": [<div className="vistoria-status status-finalizado">Laudo aprovado</div>, "status-finalizado"],
                "Laudo pronto": [<div className="vistoria-status status-pronto">Laudo pronto</div>, "status-pronto"],
            },
            badstatus: {
                "Execução": [<div className="vistoria-status status-nothing">Execução</div>, "status-vistoria"],
                "Aceito": [<div className="vistoria-status status-nothing">Aceito</div>, "status-aprovado"],
                "Aberto": [<div className="vistoria-status status-nothing">Aberto</div>, "status-aberto"],
                "Controle de qualidade": [<div className="vistoria-status status-nothing">Controle de qualidade</div>, "status-andamento"],
                "Laudo negado": [<div className="vistoria-status status-nothing">Laudo negado</div>, "status-negado"],
                "Laudo aprovado": [<div className="vistoria-status status-nothing">Laudo aprovado</div>, "status-finalizado"],
                "Laudo pronto": [<div className="vistoria-status status-nothing">Laudo pronto</div>, "status-pronto"],
            },
            mouths: {
                "0": "Janeiro",
                "1": "Fevereiro",
                "2": "Março",
                "3": "Abril",
                "4": "Maio",
                "5": "Junho",
                "6": "Julho",
                "7": "Agosto",
                "8": "Setembro",
                "9": "Outubro",
                "10": "Novembro",
                "11": "Dezembro",
            },
            vistorias: [],
            imovel: [],
            diaMes: '',
            datacão: '',
            lastid: '',
            statusSelected: '',
            linkest: process.env.REACT_APP_APP_VISTORIA

        }

        this.makeCalendar()
    }

    makeCalendar = async (data=new Date().getMonth(), types) => {
        const { status, statusSelected, badstatus, id } = this.state
        let dias = []
        let today = new Date()
        let first = new Date(today.getUTCFullYear(), data, 1)
        let last = new Date(today.getUTCFullYear(), data + 1, 0 )

        let badges = await api_vistoriador.post('/vistoriadores/gettimevistorias', {first, last, id})
        console.log(badges.data);
        
        this.setState({imovel: badges.data['imovel'].data})
        
        let vistorias = []
        for(let i of badges.data.vistorias){
            vistorias.push(i.data_agendada.split('-')[2])
        }

        if (types){
            this.setState({statusSelected: types})
        }

        let dia = 1;
        for (let i=0; i < 38; i++){
            if (i >= first.getUTCDay() && dia <= last.getDate()) {
                let ds = dia.toString()
                ds = ds.length == 1 ? `0${ds}` : ds;
                console.log('====================================');
                console.log(ds);
                console.log('====================================');
                if (vistorias.includes(ds)) {
                    let badge = [], vists = []
                    for (let k of badges.data.vistorias){
                        console.log('====================================');
                        console.log(k.data_agendada, this.correctTime(`${first.getUTCFullYear()}-${first.getMonth()+1}-${dia}`));
                        console.log('====================================');
                        if (k.data_agendada == this.correctTime(`${first.getUTCFullYear()}-${first.getMonth()+1}-${dia}`)) {
                            if (!types || (types && k.status == types)){
                                badge.push(status[k.status][0])
                            }
                            else{
                                badge.push(badstatus[k.status][0])
                            }
                            vists.push(k)
                        }
                    }
                    dias.push(
                        <div className="dia-item" data-set={dia} onClick={e=> {this.toggleCard(e, vists, `${dia}`)}}>
                            <p className="dia-num">{dia}</p>
                            {badge}
                        </div>
                    )
                }
                else{

                    dias.push(
                        <div className="dia-item">
                            <p className="dia-num">{dia}</p>
                        </div>
                    )
                }
                dia += 1
            }
            else if (dia <= last.getDate()){

                dias.push(
                    <div className="dia-item">
                        <p className="dia-num"></p>
                    </div>
                )
            }
        }
        this.setState({diasAgenda: dias, diaMes: data})
    }

    correctTime = (time) => {
        let [a, m, d] = time.split('-')
        m.length == 1 ? m = `0${m}` : m = m
        d.length == 1 ? d = `0${d}` : d = d 
        return `${a}-${m}-${d}`
    }

    toggleCard = (e, data=false, dia=false) => {
        const { classpop, diaMes, mouths, lastid } = this.state;
        
        if (classpop == "popup-agenda hide") {
            dia = e.target.dataset.set || e.target.parentNode.dataset.set;
            this.setState({classpop: 'popup-agenda', vistorias: data, datacao: `${dia} de ${mouths[`${diaMes}`]}`});
            return
        }
        if  (!e.target.classList.contains('popup-agenda')) return
        
        if (lastid != ''){

            let div = document.querySelector(`#${lastid}`)
    
            if (div.classList.contains('show')){
                div.classList.remove("show")
            }
        }

        this.setState({classpop: 'popup-agenda hide', lastid: ''})        
    }

    formatDia = (dateInput) => {
        if (!dateInput) return dateInput;
        const [ano, mes, dia] = dateInput.split('-');
        return `${dia}/${mes}/${ano}`;
    }

    formatHora = (dateInput) => {
        if (!dateInput) return dateInput;
        const [hora, minuto, segundo] = dateInput.split(':');
        return `${hora}:${minuto}`;
    }

    filterImovel = (id, imovel) => {
        if (!imovel) {
            return []
        }
        return imovel.filter( e => {
            return e.id == id
        })
    }

    openAccordion = (id) => {
        const {lastid} = this.state;

        if (lastid == id || lastid == ''){
            document.querySelector(`#${id}`).classList.toggle('show');
        }
        else{
            document.querySelector(`#${lastid}`).classList.remove('show');
            document.querySelector(`#${id}`).classList.toggle('show');
        }

        this.setState({lastid: id})
    }

    accpetvis = async (idx) => {
        const { id, statusSelected, diaMes } = this.state;
        await api_vistoriador.post('/vistoriadores/acceptVis', {id: idx, vis: id})
        this.setState({classpop: 'popup-agenda hide'})        

        this.makeCalendar(diaMes, statusSelected)
    }

    refusevis = async (idx) => {
        const { id, statusSelected, diaMes} = this.state;
        await api_vistoriador.post('/vistoriadores/refuseVis', {id: idx, vis: id})

        this.setState({classpop: 'popup-agenda hide'})        
        this.makeCalendar(diaMes, statusSelected)
    }

    render() {
        const {diasAgenda, classpop, vistorias, status, imovel, diaMes, datacao, statusSelected, linkest} = this.state
        return (
            <>  
                <link rel="stylesheet" href={require('../assets/css/Base/agenda.css')}/>
                <link rel="stylesheet" href={require('../assets/css/Base/popup-agenda.css')}/>
                <div className="upagenda">
                    <div className="content-agenda">
                        <div className="content-box-header">
                            <p>Agenda</p>
                            <div className="flex-n">
                                <select onChange={e => {this.makeCalendar(diaMes, e.target.value)}}>
                                    <option value="" defaultChecked>Todos status</option>
                                    <option value="Laudo">
                                        Laudo
                                    </option>
                                    <option value="Aberto">Aberto</option>
                                    <option value="Aceito">Aceito</option>
                                    <option value="Execução">Execução</option>
                                    <option value="Controle de qualidade">Controle de qualidade</option>
                                    <option value="Laudo pronto">Laudo pronto</option>
                                    <option value="Laudo aprovado">Laudo aprovado</option>
                                    <option value="Laudo negado">Laudo negado</option>

                                </select>
                                <select onChange={e => this.makeCalendar(e.target.value, statusSelected)} value={diaMes}>
                                    <option value="0">Janeiro</option>
                                    <option value="1">fevereiro</option>
                                    <option value="2">Março</option>
                                    <option value="3">Abril</option>
                                    <option value="4">Maio</option>
                                    <option value="5">Junho</option>
                                    <option value="6">Julho</option>
                                    <option value="7">Agosto</option>
                                    <option value="8">Setembro</option>
                                    <option value="9">Outubro</option>
                                    <option value="10">Novembro</option>
                                    <option value="11">Dezembro</option>
                                </select>
                            </div>
                        </div>
                        <div className="calendario-header">
                            <div className="dia-semana"><p>D</p></div>
                            <div className="dia-semana"><p>S</p></div>
                            <div className="dia-semana"><p>T</p></div>
                            <div className="dia-semana"><p>Q</p></div>
                            <div className="dia-semana"><p>Q</p></div>
                            <div className="dia-semana"><p>S</p></div>
                            <div className="dia-semana"><p>S</p></div>
                        </div>
                        {diasAgenda}

                    </div>


                    <div className={classpop} onClick={e => {this.toggleCard(e)}}>
                        <div className="agenda-card">
                            <div className="agenda-card-header">
                                <p>{datacao}</p>
                            </div>
                            <div className="colors-info">
                                <div className="colors-info-item">
                                    <div className="status-dia status-aberto"></div>
                                    <p>Aberto</p>
                                </div>
                                <div className="colors-info-item">
                                    <div className="status-dia status-aprovado"></div>
                                    <p>Aceito</p>
                                </div>
                                <div className="colors-info-item">
                                    <div className="status-dia status-vistoria"></div>
                                    <p>Execução</p>
                                </div>
                                <div className="colors-info-item">
                                    <div className="status-dia status-andamento"></div>
                                    <p>Controle de qualidade</p>
                                </div>
                                <div className="colors-info-item">
                                    <div className="status-dia status-pronto"></div>
                                    <p>Laudo pronto</p>
                                </div>
                                <div className="colors-info-item">
                                    <div className="status-dia status-finalizado"></div>
                                    <p>Laudo aprovado</p>
                                </div>
                                <div className="colors-info-item">
                                    <div className="status-dia status-negado"></div>
                                    <p>Laudo negado</p>
                                </div>
                            </div>
                            <div className="accordions">
                                {
                                    imovel ?
                                        vistorias.map(e => (
                                            <div className="accordion-item">
                                                <button class="accordion" onClick={k => {this.openAccordion('accordionC-' + e.id)}}>
                                                    <div className="flex-n">

                                                        <div className={`status-dia ${status[e.status][1]}`}></div>

                                                        <p>
                                                            {
                                                                this.filterImovel(e.imovel_id, imovel).map(i=>(
                                                                    `${i.rua_imovel}, ${i.numero_imovel}`
                                                                ))
                                                            }
                                                        </p>
                                                    </div>
                                                    <i class="fas fa-sort-down"></i>
                                                </button>
                                                <div className="accordion-content" id={'accordionC-' + e.id}>
                                                    <div class="accordion-content-item">
                                                            <p>Endereço</p>
                                                            <p>
                                                                {
                                                                    this.filterImovel(e.imovel_id, imovel).map(i=>(
                                                                        `${i.rua_imovel}, ${i.numero_imovel}, ${i.complemento} - ${i.cidade}, ${i.estado}`
                                                                    ))
                                                                }
                                                            </p>
                                                        </div>
                                                        <div class="accordion-content-item">
                                                            <p>Metragem</p>
                                                            <p>{this.filterImovel(e.imovel_id, imovel).map(i => i.metragem)}m²</p>
                                                        </div>
                                                    <div className="accordion-content-item">
                                                        <p>Data</p>
                                                        <p>{this.formatDia(e.data_agendada)}</p>
                                                    </div>
                                                    <div className="accordion-content-item">
                                                        <p>Horarío</p>
                                                        <p>{this.formatHora(e.hora_agendada)}</p>
                                                    </div>
                                                    <h4>Retirada da chave</h4>
                                                    <div className="accordion-content-item">
                                                        <p>Endereço</p>
                                                        <p>{e.endereco_retirada}</p>
                                                    </div>
                                                    <div className="accordion-content-item">
                                                        <p>Contato</p>
                                                        <p>{e.contato_retirada}</p>
                                                    </div>
                                                    {
                                                        e.status == "Aberto" ? (
                                                            <>
                                                                <div class="accordion-content-item">
                                                                    <button className="choosebtns" onClick={p => {this.accpetvis(e.id)}}>Aceitar</button>
                                                                    <button className="choosebtns negative" onClick={p => {this.refusevis(e.id)}}>Recusar</button>
                                                                </div>
                                                            </>
                                                        ) : (null)
                                                    }
                                                    {
                                                        e.status == "Aceito" ? (
                                                            <div class="accordion-content-item">
                                                                <a target="_blanck" href={`${linkest}/form/${e.HASH}`}>
                                                                    <button className="choosebtns accepted">Iniciar</button>
                                                                </a>
                                                            </div>
                                                        ) : (null)
                                                    }
                                                </div>
                                            </div>
                                        ))
                                        : (null)
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
            
            </>
        )
    }
}

export default AgendaPage