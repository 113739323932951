import LoginPage from './views/login';
import ForgetPage from './views/forget';
import InicioPage from './views/inicio';
import VistoriaPage from './views/vistorias';
import FinaceiroPage from './views/financeiro';
import AgendaPage from './views/agenda';
import PerguntasPage from './views/perguntas';
import MakepassPage from './views/makepass';
import TelegramPage from './views/telegram';
import PerfilPage from './views/perfil';

var routes = [
    
    {
        path: 'forget',
        component: ForgetPage,
        layout: "/",
    },

    {
        path: 'makepass/:id',
        component: MakepassPage,
        layout: "/",
    },

    {
        path: '/',
        component: LoginPage,
        layout: "/",

    },

    {
        path: '/vistorias',
        component: VistoriaPage,
        layout: "/admin",

    },

    {
        path: '/perfil',
        component: PerfilPage,
        layout: "/admin",

    },

    {
        path: '/financeiro',
        component: FinaceiroPage,
        layout: "/admin",

    },

    {
        path: '/telegram',
        component: TelegramPage,
        layout: "/admin",

    },

    {
        path: '/agenda',
        component: AgendaPage,
        layout: "/admin",

    },

    {
        path: '/perguntas/:hash',
        component: PerguntasPage,
        layout: "/admin",

    },

    {
        path: '/',
        component: InicioPage,
        layout: "/admin",

    },
]

export default routes