import React from "react";
import { api_vistoriador } from '../apis/api';


class PerguntasPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hash: props.match.params.hash,

            imovelData: '',
            perguntasData: '',
            perguntasRespostas: null,
            ft: [],
            ftFile: [],
            respondidas: [],
            vistoria: '',
            done: false,
            selection: true,
            comodos: false,
            perguntas: false,
            question: false,
            final: false,
            comodosList: [],
            selectedComodo: -1,
            selectedPergunta: '',
            descricao: '',
            respostaSelect: '',
            finals: [],
            comodosHouse: [],
            comodosAsk: [],
            aviso: false,
            avisomsg: '',
            askIndex: '',
            imobiliaria: '',
            special: false,
            specialName: '',
            outros: [],
            edit: false,
            idDados_vistoria: 0,
            indexoutros: 0,
            fthost: [],
            img: '',
            classImg: 'seeimg hide',
            caractsModal: false,
            caracList: [],
            tiposCaract: [],
            addedcaract: [],
            hostcaract: [],
            paintmaker: false,
            paintedLines: false,
            basecolorstroke: '#4677ff',
        }

        this.maxW = window.innerWidth;
        this.maxH = window.innerHeight;
        
        this.canvas = null;
        this.context = null;

        this.indeximg = -1;

        this.times = false;
        this.xline = 0;
        this.yline = 0;
        this.pxline = 0;
        this.pyline = 0;

        this.scribbles = [];

        this.pre = [];
        this.nex = [];

        this.xcanvas = 0;
        this.ycanvas = 0;

        this.canvasimg = 0;

        this.getData()
    }

    seeAsks = async (e, index) => {
        this.setState({selectedComodo: index, perguntas: true, selection: false, question: false})
    }

    seeForm = async (index, e, finish=false) => {
        const { comodosHouse, selectedComodo } = this.state;

        if  (finish) {
            let re = await api_vistoriador.post(`/formulario/${this.props.match.params.hash}/getData`, {"comodo_id": comodosHouse[selectedComodo].id, "pergunta_id": e.id});
            this.setState({
                selection: false, 
                perguntas: false, 
                spacial: false, 
                selectedPergunta: e, 
                descricao: re.data.visto[0].resposta_aberta, 
                ft: [], 
                fthost: re.data.imgs, 
                askIndex: index, 
                specialName: null, 
                respostaSelect: re.data.visto[0].resposta_id, 
                edit: true, 
                idDados_vistoria: re.data.visto[0].id, 
                question: true,
                addedcaract: re.data['addedcaract']
            });

            return
        }

        this.setState({selection: false, perguntas: false, spacial: false, selectedPergunta: e, descricao: '', ft: [], askIndex: index, specialName: '', edit: false, question: true, fthost: [], addedcaract: []});
    }

    getData = async () => {
        let re = await api_vistoriador.get(`/formulario/${this.props.match.params.hash}`)
        console.log(re);
        
        
        if (re['status'] == 400){
            this.setState({done: "error"});
            return;
        }

        re = re['data']

        let list = [];
        for (let i of re['comodosList']) {
            list.push({
                "id": i.id, 
                "comodo": i.comodo_id, 
                "nome": re['comodos'].filter(e => e.id === i.comodo_id)[0]['nome'],
                "perguntas": re['perguntas'].filter(e => i.comodo_id === e.comodo_id)
            });
        }

        let respondidas = []
        for (let i of re['respondidas']){
            let id = `${i.comodos_id}`;
            let j = {};
            j[id] = i.perguntas_id;
            respondidas.push(j)
        }

        this.setState({
            imovelData: re['imovel'].data[0], 
            comodosList: re['comodos'], 
            vistoria: re['vistoria'], 
            perguntasData: re['perguntas'], 
            perguntasRespostas: re['perguntasRespostas'], 
            comodosHouse: list, 
            respondidas: respondidas, 
            imobiliaria: re['imobiliaria'], 
            outros: re['outros'],
            tiposCaract: re['caracteristicas'],
            caracList: re['allcaracteristicas']
        });  
    }

    blueSquare = (e) => {
        const { selectedComodo, comodosHouse, respondidas } = this.state;
        for (let i of respondidas) {
            if (comodosHouse[selectedComodo].id in i){
                if (e.id === i[comodosHouse[selectedComodo].id]){
                    return true;
                }
            }
        }
        return false;
    }

    setComodo = async (index) => {
        this.setState({selectedComodo: index});
        this.seeAsks(false, index);
    }

    changeComodo = async (e) => {
        const { comodosList, comodosHouse, perguntasData } = this.state;

        let comodo = comodosList[e.target.value];
        let re = await api_vistoriador.post(`/formulario/${this.props.match.params.hash}/adicionar`, {"id": comodo.id});


        let list = {"id": re.data.id.insertId, "comodo": comodo.id};
        list['nome'] = comodosList.filter(e => e.id === comodo.id)[0]['nome']
        list['perguntas'] = perguntasData.filter(e => e.comodo_id === comodo.id)

        this.setState({comodosHouse: comodosHouse.concat(list)});
        this.setComodo(comodosHouse.length);
        
    }

    goBackComodos = async () => {
        this.setState({selection: true, perguntas: false, question: false, special: false})
    }

    goBackAsk = async () => {
        this.setState({selection: false, perguntas: true, question: false, special: false})
    }

    respostasPergunta = () => {
        const {perguntasRespostas, selectedPergunta} = this.state;

        return perguntasRespostas.filter(j => {
            return j.pergunta_id === selectedPergunta.id;
        });
    }

    appendImage = async (e, i) => {
        this.setState({ft: this.state.ft.concat(e.target.result), ftFile: this.state.ftFile.concat(i)});
    }

    addImage = async (e) => {
        for (let i of e.target.files) {
            if (!i.type.match('image.*')) {
                continue;
            }

            let rd = new FileReader();

            rd.onload = (j) => this.appendImage(j, i);

            rd.readAsDataURL(i)
        }
    }

    updateAsk = async () => {
        const { descricao, respostaSelect, idDados_vistoria, specialName, outros, indexoutros, addedcaract } = this.state;
        if (specialName) {
            outros[indexoutros].pergunta_especial = specialName;
        }
        let {data} = await api_vistoriador.post(`/formulario/${this.props.match.params.hash}/update`, {addedcaract, descricao, respostaSelect, idDados_vistoria, specialName});

        if (data['visto']){
            this.sendImages(idDados_vistoria);
        }

        this.setState({selection: false, perguntas: true, question: false, special: false});

    }

    sendImages = async (id) => {
        const {ftFile} = this.state;

        for (let i of ftFile){
            let form = new FormData();

            form.append('foto', i);
            form.append('id', id);

            api_vistoriador.post(`/formulario/${this.props.match.params.id}/image`, form);

        }
    }

    sendPergunta = async () => {
        const { addedcaract, descricao, respostaSelect, selectedPergunta, comodosHouse, selectedComodo, ftFile, respondidas, specialName } = this.state;

        if (descricao === null || respostaSelect === -1 || descricao === ''){
            this.toggleAviso("Preencha os campos");
            return;
        }
        else if (descricao.length > 10 && ftFile.length === 0) {
            this.toggleAviso("Insira um imagem");
            return;
        }

        let response = {}
        response[comodosHouse[selectedComodo].id] = selectedPergunta.id;

        let {data} = await api_vistoriador.post(`/formulario/${this.props.match.params.hash}`, {addedcaract, descricao, specialName, respostaSelect, perguntaId: selectedPergunta.id, nomeComodo: comodosHouse[selectedComodo].nome, comodoId: comodosHouse[selectedComodo].id});
        
        if (data['visto']){

            this.sendImages(data['visto'].insertId);
        }

        this.setState({selection: false, perguntas: true, question: false, special: false, respondidas: respondidas.concat(response)});
    }

    openImg = async (e, s=false) => {
        const {classImg} = this.state;

        if (classImg == "seeimg") {
            this.setState({img: e, classImg: 'seeimg hide'})
            return
            
        }
        this.setState({img: e, classImg: 'seeimg'})

    }

    seeSpecialForm = async (index, id, finish=false) => {
        if (finish) {
            let re = await api_vistoriador.post(`/formulario/${this.props.match.params.hash}/getData/${id}`);
            this.setState({indexoutros: index, selection: false, question: false, perguntas: false, special: true, edit: true, respostaSelect: null, descricao: re.data.visto[0].resposta_aberta, idDados_vistoria: re.data.visto[0].id, specialName: re.data.visto[0].pergunta_especial, fthost: re.data.imgs, ftFile: []});
            return
        }

        this.setState({selection: false, question: false, perguntas: false, special: true, specialName: '', edit: false, respostaSelect: null, descricao: '', ft: [], ftFile: []});
    }

    sendSpecialAsk = async () => {
        const { descricao, comodosHouse, selectedComodo, specialName, outros, ftFile, addedcaract } = this.state;

        if (descricao === null || specialName === '' || descricao === ''){
            alert("Preencha os campos");
            return;
        }
        else if (descricao.length > 10 && ftFile.length === 0) {
            alert("Insira uma imagem");
            return;
        }

        let {data} = await api_vistoriador.post(`/formulario/${this.props.match.params.hash}`, {addedcaract, descricao, specialName, nomeComodo: comodosHouse[selectedComodo].nome, comodoId: comodosHouse[selectedComodo].id});

        if (data['visto']){

            this.sendImages(data['visto'].insertId);
        }

        this.setState({outros: outros.concat(data.outro[0]), selection: false, question: false, perguntas: true, special: false, specialName: '', descricao: '', ftFile: [], ft: []})

    }

    removeImg = async (index) => {
        const { ft, ftFile } = this.state;
        ft.splice(index,1)
        ftFile.splice(index,1)
        this.setState({ft: ft, ftFile: ftFile})
        
    }

    removeHostImg = async (index, indeximg) => {
        const {fthost} = this.state;
        await api_vistoriador.post(`/formulario/${this.props.match.params.hash}/del/img`, {id: index});

        fthost.splice(indeximg, 1)
        this.setState({fthost: fthost})
    }

    setblocked = async (id) => {
        
        let d = getComputedStyle(document.querySelector(`#${id}`)).getPropertyValue("display")
        if (d == "block") {
            document.querySelector(`#${id}`).style.display = "none";
        }
        else{
            document.querySelector(`#cores`).style.display = "none";
            document.querySelector(`#materiais`).style.display = "none";
            document.querySelector(`#${id}`).style.display = "block";

        }
    }

    closefix = async (e) => {
        if (e.target.classList.contains('paintMaker')){
            this.setState({caractsModal: false})
        }
    }

    addcaract = async (e, u) => {
        let {addedcaract} = this.state;

        if(e.target.checked == true){
            let c = {"tipo_caracteristicas_id": u.tipo_caracteristicas_id, "descricao": u.descricao};
            
            addedcaract.push(c)
            this.setState({addedcaract: addedcaract})
        }
        else{
            addedcaract = addedcaract.filter(i => i.descricao != u.descricao);
            this.setState({addedcaract: addedcaract})

        }
    }

    saveScribbles = async (l, k) => {
        k = k || false

        if (!k) {
            this.nex = []
        }

        (l || this.pre).push(this.canvas.toDataURL());
    }

    resScribbles = async (giver, taker) => {
        if (giver.length) {
            this.saveScribbles(taker, true)

            let r = giver.pop()
            let i = new Image()
            i.src = r;

            i.onload = () => {
                try{
                    this.context.clearRect(0, 0, this.xcanvas, this.ycanvas);
                    this.context.drawImage(this.canvasimg, 0, 0, this.xcanvas, this.ycanvas)
                    this.context.drawImage(i, 0, 0, this.xcanvas, this.ycanvas)
                    this.context = this.canvas.getContext("2d")
                }
                catch{}
            }
        }
    }

    undo = async () => {
        this.resScribbles(this.pre, this.nex)
    }

    redo = async () => {
        this.resScribbles(this.nex, this.pre)
    }

    startDrawtouch = async (e) => {
        let touch = e.touches[0];

        this.xline = touch.clientX - this.canvas.offsetLeft;
        this.yline = touch.clientY - this.canvas.offsetTop;

        this.pxline = touch.clientX - this.canvas.offsetLeft;
        this.pyline = touch.clientY - this.canvas.offsetTop;

        let mouseEvent = new MouseEvent("mousedown", {
            clientX: touch.clientX,
            clientY: touch.clientY
        });

        this.canvas.dispatchEvent(mouseEvent);
    }

    finalDrawtouch = async (e) => {
        let mouseEvent = new MouseEvent("mouseup", {});
        this.canvas.dispatchEvent(mouseEvent);
    }

    makeLinestouch = async (e) => {
        let touch = e.touches[0];

        let mouseEvent = new MouseEvent("mousemove", {
            clientX: touch.clientX,
            clientY: touch.clientY
        });

        this.canvas.dispatchEvent(mouseEvent);
    }

    startDraw = async (e) => {
        if (!this.context.canvas){
            this.context = this.canvas.getContext("2d");
        }

        this.pxline = this.xline;
        this.pyline = this.yline;
        this.xline = e.clientX - this.canvas.offsetLeft;
        this.yline = e.clientY - this.canvas.offsetTop;

        this.times = true
        this.saveScribbles()
        this.context.beginPath();
        this.context.moveTo(this.pxline, this.pyline);
        try{
            this.draw() 
        }
        catch{}
    }

    finalDraw = async (e) => {
        this.times = false
    }

    makeLines = async (e) => {
        this.pxline = this.xline;
        this.pyline = this.yline;
        this.xline = e.clientX - this.canvas.offsetLeft;
        this.yline = e.clientY - this.canvas.offsetTop;
    }

    draw = async (e) => {
        const {paintedLines, basecolorstroke} = this.state;

        if (this.times && paintedLines){
                
            this.context.lineJoin = "round";
            this.context.lineTo(this.xline, this.yline);
            this.context.strokeStyle = basecolorstroke;
            this.context.lineWidth = 7;
            this.context.stroke();
            setTimeout(this.draw, 1)
        }
    }

    saveimage = async (index) => {
        let {ftFile, ft} = this.state;

        ft[this.indeximg] = this.canvas.toDataURL();

        let file

        this.canvas.toBlob((e) => {
            file = e
            file.lastModifiedDate = new Date();
            file.name = new Date().getDate() + ".jpg";

            ftFile[this.indeximg] = file

            this.pre = [];
            this.nex = [];

            this.setState({ft: ft, ftFile: ftFile, paintmaker: false, paintedLines: false})
            
        }, 'image/jpeg', 1)
    }

    fiximagesize = async (index) => {
        const {ft} = this.state;
        this.setState({paintmaker: true});

        this.indeximg = index;
        

        setTimeout(() => {
            
            this.canvas = document.getElementById('nkonvas');
            let smenu = document.querySelector('div.smenucanvas');
            this.context = this.canvas.getContext("2d");

            let w = this.maxW * 0.875;
            let h = this.maxH * 0.875;

            let img = new Image()

            img.src = ft[index]

            img.onload = () => {
                
                let r = img.width / img.height

                let mw = ((h * img.width) / img.height);
                let mh = ((w * img.height) / img.width);
                
                if (mh <= h) {
                    img.height = mh;
                }
                else {
                    img.height = h;
                }
                if (mw <= w) {
                    img.width = mw;
                }
                else {
                    img.width = w;
                }
                
                this.xcanvas = this.canvas.width = img.width;
                this.ycanvas = this.canvas.height = img.height;

                smenu.style.width = img.width + "px";

                this.context.clearRect(0, 0, img.width, img.height);
                this.context.drawImage(img, 0, 0, img.width, img.height)

                this.canvasimg = img

                this.canvas.addEventListener("mousedown", this.startDraw)
                this.canvas.addEventListener("mouseup", this.finalDraw, false);
                this.canvas.addEventListener("mousemove", this.makeLines, false);

                this.canvas.addEventListener("touchstart", this.startDrawtouch)
                this.canvas.addEventListener("touchend", this.finalDrawtouch, false);
                this.canvas.addEventListener("touchmove", this.makeLinestouch, false);

            }

        }, 20);
    }

    render() {
        const { 
            img, fthost, classImg,  question, specialName, edit,
            respostaSelect, descricao, outros, special, imobiliaria, 
            aviso, final, avisomsg, imovelData, perguntas, done, vistoria, 
            selection, comodos, ft, comodosList, selectedComodo, 
            selectedPergunta, comodosHouse, caractsModal, addedcaract,
            caracList, hostcaract, tiposCaract, paintedLines, paintmaker, basecolorstroke
        } = this.state;

        return (
            <>
                <link rel="stylesheet" href={require('../assets/css/vistoria/style.css')}/>
                <div className={classImg} onClick={e => {this.openImg(null)}}>
                    <img src={img} alt=""/>
                </div>
                    {
                        vistoria.status == "Controle de qualidade" && selection == true && done == false && 
                        <div className="selecaoVistoria">
                        
                            <header>
                                <section id="sel-com">
                                    <form>
                                        <div class="campo-input">
                                            <label>Adicionar novo cômodo</label>
                                            <div class="campo-input-sel">
                                                <select onChange={i => this.changeComodo(i)}>
                                                    <option>Selecione o cômodo</option>
                                                    {
                                                        comodosList.map((e, index) => (
                                                            <option value={index}>{e.nome}</option>

                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </form>
                                </section>
                            </header>

                            <div className="gridlay">
                                {
                                    comodosHouse.map((e, index) => (
                                        <div class='item' onClick={i => {this.seeAsks(e, index)}}>
                                            { index + 1 }&nbsp;&nbsp;{e.nome}
                                        </div>
                                    ))
                                }
                            </div>

                        </div>
                    }

                    {
                        vistoria.status == "Controle de qualidade" && perguntas == true && done == false && 
                        <div className="selecaoVistoria">
                        
                            <header>
                               <span onClick={this.goBackComodos}>
                                   voltar
                               </span>
                               {comodosHouse[selectedComodo].nome}
                            </header>

                            <div className="gridlay">

                                {
                                    comodosHouse[selectedComodo].perguntas.map((e, index) => (
                                        this.blueSquare(e) === true ? (
                                            <div class='item item-ok' onClick={i => {this.seeForm(index, e, true)}}>
                                                {e.descricao}
                                            </div>
                                        ) : (
                                            <div class='item' onClick={i => {this.seeForm(index, e)}}>
                                                {e.descricao}
                                            </div>)
                                    ))
                                }
                                {
                                    outros.map((e, index) => (
                                        e.comodo_id == comodosHouse[selectedComodo].id ? (
                                            <div class='item item-ok' onClick={i => {this.seeSpecialForm(index, e.id, true)}}>
                                                {e.pergunta_especial}
                                            </div>
                                        ) : (
                                            null
                                        )
                                    ))
                                }
                                <div class='item' onClick={i => {this.seeSpecialForm(false)}}>
                                    Outro
                                </div>
                            </div>

                        </div>
                    }
                    {
                        vistoria.status == "Controle de qualidade" && question == true && done == false && 
                            <div className="selecaoVistoria">
                            
                                <header>
                                    <span onClick={this.goBackAsk}>
                                        voltar
                                    </span>
                                    {comodosHouse[selectedComodo].nome} - {selectedPergunta.descricao}
                                </header>

                                <div class="com-desc-itens">
                                <form>  
                                    <div class="desc-item">
                                        <select value={respostaSelect} onChange={e => {this.setState({respostaSelect: e.target.value})}}>
                                            <option value="-1" defaultChecked>Selecione</option>
                                            {   
                                                this.respostasPergunta().map((e, index) => (      
                                                    <option value={e.id}>{e.descricao}</option> 
                                                ))
                                            }
                                        </select>
                                    </div>

                                    <div class="d-flex-center">
                                        <div class="btn-itens" onClick={s => this.setblocked("materiais")}>
                                            <i class="fas fa-layer-group"></i>
                                            <p>Materiais</p>
                                        </div>
                                        <div class="modal" id="materiais" >
                                            <div class="modal-item" onClick={() => this.setState({caractsModal: true, typecaracs: 2})}>Inserir Materiais</div>
                                            <div class="modal-item"  onClick={s => {
                                                document.querySelector("#materiais").style.display = "none"
                                            }}>Não se aplica</div>
                                        </div>
                                        


                                        <div class="btn-itens"  onClick={s => this.setblocked('cores')}>
                                            <i class="fas fa-palette"></i>
                                            <p>Cores</p>
                                        </div>
                                        <div class="modal" id="cores">
                                            <div class="modal-item" onClick={() => this.setState({caractsModal: true, typecaracs: 1})}>Inserir Cores</div>
                                            <div class="modal-item"  onClick={s => {
                                            document.querySelector("#cores").style.display = "none"
                                        }}>Não se aplica</div>
                                        </div>
                                    
                                    </div>

                                    <div class="desc-item-cam">
                                        <input hidden class="btn-cam" multiple id="files" accept="image/*" type="file" onChange={(e) => this.addImage(e)}/>
                                        <label htmlFor="files" class="btn-cam">
                                            Inserir fotos
                                            <i class="fas fa-camera"></i>
                                        </label>
                                    </div>
                                    <div id="list">
                                        {
                                            ft.map((e, index) => (
                                                <div class="seele">
                                                    <img src={e} alt="" />
                                                    <div class="opts">
                                                        <i class="fas fa-times" onClick={a => {this.removeImg(index)}}></i>
                                                        <i class="fas fa-eye" onClick={g => {this.fiximagesize(index)}}></i>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                        {
                                            fthost.map((e, index) => (
                                                <div class="seele">
                                                    <img src={e.caminho} alt="" />
                                                    <div class="opts">
                                                        <i class="fas fa-times" onClick={g => {this.removeHostImg(e.id, index)}}></i>
                                                        <i class="fas fa-eye" onClick={g => {this.openImg(e.caminho)}}></i>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div class="desc-item-desc">
                                        <label>Observações</label>
                                        <textarea value={descricao}  placeholder="Escreva aqui..." onChange={e => {this.setState({descricao: e.target.value})}} required></textarea>
                                    </div>
                                    <footer>
                                        {
                                            edit === false &&
                                                <button type="button" onClick={this.sendPergunta}>Salvar</button>
                                        }
                                        {
                                            edit === true &&
                                                <button type="button" onClick={this.updateAsk}>Editar</button>
                                        }
                                    </footer>
                                </form>
                            </div>
                        </div>
                    }

                    {
                        vistoria.status == "Controle de qualidade" && special == true && done == false && 
                            <div className="selecaoVistoria">
                            
                                <header>
                                    <span onClick={this.goBackAsk}>
                                        voltar
                                    </span>
                                    {comodosHouse[selectedComodo].nome} - Outro
                                </header>

                                <div class="com-desc-itens">
                                <form>  
                                    <div class="desc-item">
                                        <input type="text" value={specialName} onChange={a => {this.setState({specialName: a.target.value})}} />
                                    </div>

                                    <div class="d-flex-center">
                                        <div class="btn-itens" onClick={s => this.setblocked("materiais")}>
                                            <i class="fas fa-layer-group"></i>
                                            <p>Materiais</p>
                                        </div>
                                        <div class="modal" id="materiais" >
                                            <div class="modal-item" onClick={() => this.setState({caractsModal: true, typecaracs: 2})}>Inserir Materiais</div>
                                            <div class="modal-item"  onClick={s => {
                                                document.querySelector("#materiais").style.display = "none"
                                            }}>Não se aplica</div>
                                        </div>
                                        
                                        <div class="btn-itens"  onClick={s => this.setblocked('cores')}>
                                            <i class="fas fa-palette"></i>
                                            <p>Cores</p>
                                        </div>
                                        <div class="modal" id="cores">
                                            <div class="modal-item" onClick={() => this.setState({caractsModal: true, typecaracs: 1})}>Inserir Cores</div>
                                            <div class="modal-item"  onClick={s => {
                                            document.querySelector("#cores").style.display = "none"
                                        }}>Não se aplica</div>
                                        </div>
                                    
                                    </div>

                                    <div class="desc-item-cam">
                                        <input hidden class="btn-cam" multiple id="files" accept="image/*" type="file" onChange={(e) => this.addImage(e)}/>
                                        <label htmlFor="files" class="btn-cam">
                                            Inserir fotos
                                            <i class="fas fa-camera"></i>
                                        </label>
                                    </div>
                                    <div id="list">
                                        {
                                            ft.map((e, index) => (
                                                <div class="seele">
                                                    <img src={e} alt="" />
                                                    <div class="opts">
                                                        <i class="fas fa-times" onClick={a => {this.removeImg(index)}}></i>
                                                        <i class="fas fa-eye" onClick={g => {this.fiximagesize(index)}}></i>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                        {
                                            fthost.map((e, index) => (
                                                
                                                <div class="seele">
                                                    <img src={e.caminho} alt="" />
                                                    <div class="opts">
                                                        <i class="fas fa-times" onClick={g => {this.removeHostImg(e.id, index)}}></i>
                                                        <i class="fas fa-eye" onClick={g => {this.openImg(e.caminho)}}></i>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div class="desc-item-desc">
                                        <label>Observações</label>
                                        <textarea value={descricao}  placeholder="Escreva aqui..." onChange={e => {this.setState({descricao: e.target.value})}} required></textarea>
                                    </div>
                                    <footer>
                                        {
                                            edit === false &&
                                                <button type="button" onClick={this.sendSpecialAsk}>Salvar</button>
                                        }
                                        {
                                            edit === true &&
                                                <button type="button" onClick={this.updateAsk}>Editar</button>
                                        }
                                    </footer>
                                </form>
                            </div>
                        </div>
                    }

                    {
                        caractsModal == true &&
                        <div className="paintMaker" onClick={e => this.closefix(e)}>
                            <div className="lambdaCaracs">
                                <header>
                                    <p>{tiposCaract[parseInt(this.state.typecaracs) - 1].descricao}</p>
                                </header>

                                {
                                    caracList.filter(u => u.tipo_caracteristicas_id == this.state.typecaracs).map(u => (
                                        <label className="caracslabel">
                                            {
                                                addedcaract.filter(o => o.descricao == u.descricao).length > 0  || hostcaract.filter(o => o.descricao == u.descricao).length > 0 ? (
                                                    <input checked type="checkbox" hidden onChange={g => this.addcaract(g, u)}/>
                                                ) : (
                                                    <input type="checkbox" hidden onChange={g => this.addcaract(g, u)}/>
                                                )
                                            }
                                            <div className="caracsstyle">{u.descricao}</div>

                                        </label>
                                    ))
                                }
                            </div>
                        </div>
                    }

                    {
                        paintmaker == true &&
                        <div className="paintMaker" onClick={e => this.closefix(e)}>
                            <canvas id="nkonvas"></canvas>
                            <div className="smenucanvas">
                                <ul>
                                    {
                                        paintedLines == false ? (
                                            <li onClick={e => this.setState({paintedLines: !paintedLines})}>
                                                <i className="fas fa-pen"></i> Desenhar
                                            </li>
                                        ) : (
                                            <>
                                                <li onClick={e => this.setState({paintedLines: !paintedLines})}>
                                                    <i className="fas fa-times"></i>&nbsp;Parar
                                                </li>

                                                <li>
                                                    <input type="color" name="" id="" value={basecolorstroke} onChange={a => this.setState({basecolorstroke: a.target.value})}/>
                                                </li>

                                                <li onClick={this.undo}>
                                                    <i class="fas fa-undo"></i>
                                                </li>

                                                <li onClick={this.redo}>
                                                    <i class="fas fa-redo"></i>
                                                </li>

                                            </>
                                        )
                                    }
                                    <li className="saves" onClick={this.saveimage}>
                                        <i className="fas fa-check" ></i> Salvar
                                    </li>
                                </ul>
                            </div>
                        </div>
                    }
            </>
        )
    };
}

export default PerguntasPage;