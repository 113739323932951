import React from "react";
import jwtDecode from 'jwt-decode';
import { api_vistoriador } from '../apis/api'

class MakepassPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            senha: '',
            email: '',
            confirme: '',
            eyes: 'fas fa-eye',
            typefield: 'password'

        }
    }

    makeLogin = async (e) => {
        e.preventDefault();
        const {email, senha, confirme} = this.state;

        if (confirme != senha) {
            alert("As senhas não são iguais")
            return
        }

        let {data} = await api_vistoriador.post('/usuarios/newpass', {email, senha, token: this.props.match.params.id});

        localStorage.setItem("tokenvisto", data['token']);
        localStorage.setItem("nomevisto", jwtDecode(data['token'])['nome']);
        this.props.history.push('/admin')
        
    } 

    chageeye = () => {
        const {typefield} = this.state;

        if (typefield == 'password'){
            this.setState({typefield: 'text', eyes: 'fas fa-eye-slash'});
            return
        }

        this.setState({typefield: 'password', eyes: 'fas fa-eye'});

    }

    render() {
        const {typefield, eyes} = this.state;

        return (
            <>
                <link rel="stylesheet" href={require('../assets/css/Base/login.css')}/>
                <link rel="stylesheet" href={require('../assets/css/Base/style.css')}/>
                <h1>NOVA SENHA</h1>
                <form onSubmit={this.makeLogin}>
                    <div class="campo-login">
                        <label for="login-email">Email</label>
                        <div class="campo-login-input">
                            <i class="far fa-envelope"></i>
                            <input type="email" id="login-email" placeholder="exemplo@exemplo.com" onChange={e => {this.setState({email: e.target.value})}} required/>
                        </div>
                    </div>
                    <div class="campo-login">
                        <label for="login-senha">Senha</label>
                        <div class="campo-login-input">
                            <i class="fas fa-lock"></i>
                            <input type={typefield} id="login-senha" placeholder="Digite sua senha" onChange={e => {this.setState({senha: e.target.value})}} required/>
                            <i class={eyes} onClick={this.chageeye}></i>
                        </div>
                    </div>
                    <div class="campo-login">
                        <label for="login-senha">Confirme</label>
                        <div class="campo-login-input">
                            <i class="fas fa-lock"></i>
                            <input type={typefield} id="login-senha" placeholder="Confirme sua senha" onChange={e => {this.setState({confirme: e.target.value})}} required/>
                            <i class={eyes} onClick={this.chageeye}></i>
                        </div>
                    </div>
                    
                    <div class="campo-login-submit">
                        <input type="submit" value="Entrar"/>
                    </div>
                </form>
            </>
        )
    }
}

export default MakepassPage