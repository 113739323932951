import React from 'react';
import { api_admin } from '../apis/api';
import jwtDecode from 'jwt-decode';
import {Link} from 'react-router-dom';

class LoginPage extends React.Component{
    constructor(props){
        super(props);

        if (localStorage.getItem('tokenvisto') && localStorage.getItem('al')) {
            try{
                jwtDecode(localStorage.getItem('tokenvisto'));
                this.props.history.push('/admin')

            }
            catch{}
            
        }

        this.state = {
            email: '',
            senha: '',
            autoLogin: false,
            eyes: 'fas fa-eye',
            typefield: 'password'
        }
    }

    makeLogin = async (e) => {
        const {email, senha, autoLogin} = this.state;
        e.preventDefault();

        let {data} = await api_admin.post('/vistoriadores/login', {email, senha});

        if (data['msg'] != undefined){
            alert(data['msg']);
            return
        }
        if (autoLogin) {
            localStorage.setItem("al", autoLogin);
        }
        console.log();
        
        localStorage.setItem("tokenvisto", data['token']);
        localStorage.setItem("nomevisto", jwtDecode(data['token'])['nome']);
        this.props.history.push('/admin')
        
    }

    chageeye = () => {
        const {typefield} = this.state;

        if (typefield == 'password'){
            this.setState({typefield: 'text', eyes: 'fas fa-eye-slash'});
            return
        }

        this.setState({typefield: 'password', eyes: 'fas fa-eye'});

    }

    render() {
        const {autoLogin, typefield, eyes} = this.state;

        return (
            <>
                <link rel="stylesheet" href={require('../assets/css/Base/login.css')}/>
                <link rel="stylesheet" href={require('../assets/css/Base/style.css')}/>
                <h1>LOGIN</h1>
                <form onSubmit={this.makeLogin}>
                    <div class="campo-login">
                        <label for="login-email">Email</label>
                        <div class="campo-login-input">
                            <i class="far fa-envelope"></i>
                            <input type="email" id="login-email" placeholder="exemplo@exemplo.com" onChange={e => {this.setState({email: e.target.value})}} required/>
                        </div>
                    </div>
                    <div class="campo-login">
                        <label for="login-senha">senha</label>
                        <div class="campo-login-input">
                            <i class="fas fa-lock"></i>
                            <input type={typefield} id="login-senha" placeholder="Digite sua senha" onChange={e => {this.setState({senha: e.target.value})}} required/>
                            <i class={eyes} onClick={this.chageeye}></i>
                        </div>
                    </div>
                    <div class="campo-login-info">
                        <div class="campo-login-info-item">
                            <input type="checkbox" id="lembrar" checked={autoLogin} onChange={e => {this.setState({autoLogin: e.target.checked})}}/>
                            <label for="lembrar">Lembrar-me</label>
                        </div>
                        <Link to="/forget">Esqueci a senha</Link>
                    </div>
                    <div class="campo-login-submit">
                        <input type="submit" value="Entrar"/>
                    </div>
                </form>
            </>
        )
    }
}

export default LoginPage