import React from 'react';
import routes from '../routes';
import { Switch, Route } from 'react-router-dom';
import MenuComponent from '../components/menu'

class AdminLayout extends React.Component {
    constructor(props) {
        super(props);
        if (localStorage.getItem('tokenvisto') == null){
            this.props.history.push('/')
        }
    }

    getRoutes = routes => {
        if (localStorage.getItem('tokenvisto') != null) {
            
            return routes.map((prop, key) => {
                if (prop.layout === "/admin") {
                    
                    return (
                        <Route
                            path={prop.layout + prop.path}
                            component={prop.component}
                            key={key}
                        />
                     );
                } else {
                    return null;
                }
            });
        }
        return []
    };

    render() {
        return (
            <>
                <link rel="stylesheet" href={require('../assets/css/Base/style.css')}/>
                <div class="container-menu">
                    <MenuComponent></MenuComponent>
                    <div class="content-body">
                        <Switch>{this.getRoutes(routes)}</Switch>	
                    </div>
                </div>
            </>
        )
    }
}

export default AdminLayout