const axios = require('axios')

// const api_vistoriador = axios.create({baseURL: process.env.REACT_APP_API, withCredentials: true, xsrfCookieName: "giraffas", headers: {
//     credentials: 'same-origin',
//     whitehouse: '%2DAsxx#@$515asdv545@#$asd6@#EPAR',
//     "Access-Control-Allow-Credentials": true,
// }})

const api_vistoriador = axios.create({baseURL: process.env.REACT_APP_API});
const api_admin = axios.create({baseURL: process.env.REACT_APP_ADMIN_API});

console.log(process.env.REACT_APP_API);


export {
    api_vistoriador,
    api_admin
}