import React from 'react';
import routes from '../routes';
import { Switch, Route } from 'react-router-dom';

class SignLayout extends React.Component{

    getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.layout === "/") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                 );
            } else {
                return null;
            }
        });
    };

    render() {
        return (
            <>
                <div class="container">
                    <div class="content-header">
                        <img src={require('../assets/img/logo.png')} />
                    </div>
                    <div class="content-login">
                        <Switch>{this.getRoutes(routes)}</Switch>
                    </div>
                </div>
            </>
        )
    }
}

export default SignLayout