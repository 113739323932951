import jwtDecode from 'jwt-decode';
import React from 'react';
import { api_vistoriador } from '../apis/api';
import { Link } from "react-router-dom"
import Axios from 'axios';

class VistoriaPage extends React.Component{
    constructor(props) {
        super(props);

        let token = localStorage.getItem('tokenvisto');
        token = jwtDecode(token);

        this.state = {
            id: token.id,
            vistorias: [],
            imovel: [],
            status: {
                "Execução": [<div className="vistoria-status status-vistoria">Execução</div>, "status-vistoria"],
                "Aceito": [<div className="vistoria-status status-aprovado">Aceito</div>, "status-aprovado"],
                "Aberto": [<div className="vistoria-status status-aberto">Aberto</div>, "status-aberto"],
                "Controle de qualidade": [<div className="vistoria-status status-andamento">Controle de qualidade</div>, "status-andamento"],
                "Laudo negado": [<div className="vistoria-status status-negado">Laudo negado</div>, "status-negado"],
                "Laudo aprovado": [<div className="vistoria-status status-finalizado">Laudo aprovado</div>, "status-finalizado"],
                "Laudo pronto": [<div className="vistoria-status status-pronto">Laudo pronto</div>, "status-pronto"],
            },
            lastid: '',
            endereco: '',
            datae: '',
            hora: '',
            metragem: '',
            statusselect: '',
            linkest: process.env.REACT_APP_APP_VISTORIA

        }

        this.getdata();
    }


    getdata = async () => {
        const { id } = this.state;
        let {data} = await api_vistoriador.post('/vistoriadores/getvistoriasFULL', {id});
        // let {data} = await Axios.post(' http://apieparteste.anvali.com.br:3030/vistoriadores/getvistoriasFULL', {id})
        this.setState({
            vistorias: data['vistorias'],
            imovel: data['imovel'].data
        })  
    }

    formatDia = (dateInput) => {
        if (!dateInput) return dateInput;
        const [dataInput, horaInput] = dateInput.split(' ');
        const [ano, mes, dia] = dataInput.split('-');
        return `${dia}/${mes}/${ano}`;
    }

    formatHora = (dateInput) => {
        if (!dateInput) return dateInput;
        const [hora, minuto] = dateInput.split(':');
        return `${hora}:${minuto}`;
    }

    filterImovel = (id, imovel) => {
        return imovel.filter( e => {
            return e.id == id
        })
    }

    openAccordion = (id) => {
        const {lastid} = this.state;

        if (lastid == id || lastid == ''){
            document.querySelector(`#${id}`).classList.toggle('show');
        }
        else{
            if (document.querySelector(`#${lastid}`)){
                document.querySelector(`#${lastid}`).classList.remove('show');

            }
            document.querySelector(`#${id}`).classList.toggle('show');
        }

        this.setState({lastid: id})
    }

    filtrar = (dados) => {
        const {lastid} = this.state;

        return dados.filter(v => {
            return this.filtrarend(v) && this.filtrardatae(v) && this.filtrarhora(v) && this.filtrarmetragem(v) && this.filtrarstatus(v);
        })
    }

    filtrarend = (v) => {
        return !this.state.endereco || (v.endereco_retirada &&
            v.endereco_retirada.toString().toLowerCase().includes(this.state.endereco));
    }
        
    filtrardatae = (v) => {
        return !this.state.datae || (v.data_agendada &&
            this.formatDia(v.data_agendada).includes(this.state.datae));
    }
            
    filtrarhora = (v) => {
        return !this.state.hora || (v.hora_agendada &&
            this.formatHora(v.hora_agendada).includes(this.state.hora));
    }

    filtrarstatus = (v) => {
        return !this.state.statusselect || (v.status &&
            v.status.toString().includes(this.state.statusselect.toString()));
    }

    filtrarmetragem = (v) => {
        const {imovel} = this.state;

        let imov = this.filterImovel(v.imovel_id, imovel)[0]

        return !this.state.metragem || (imov.metragem &&
            imov.metragem.toString().toLowerCase().includes(this.state.metragem));
    }

    accpetvis = async (idx) => {
        const { id } = this.state;
        await api_vistoriador.post('/vistoriadores/acceptVis', {id: idx, vis: id})

        this.getdata()
    }

    refusevis = async (idx) => {
        const { id } = this.state;
        await api_vistoriador.post('/vistoriadores/refuseVis', {id: idx, vis: id})

        this.getdata()
    }

    render () {
        const {vistorias, imovel, status, endereco, datae, hora, metragem, statusselect, linkest} = this.state;

        return (
            <>
                <div class="content-accordions">
                    <div class="content-box-header">
                        <p>vistorias</p>
                    </div>
                        <div className="accordions accordion-filters">
                            <div class="accordion-item">
                                <button class="accordion" onClick={k => {this.openAccordion('accordionTcast')}}>
                                    <div className="flex-n">
                                        <p>
                                            Filtros
                                        </p>
                                    </div>
                                        <i class="fas fa-sort-down"></i>
                                </button>
                                <div class="accordion-content" id={'accordionTcast'}>
                                    
                                    <div class="accordion-content-item">
                                        <p>Endereço</p>
                                        <div className="flex-n">
                                            <label htmlFor="endf"><i class="fas fa-search"></i></label>
                                            <input type="text" id="endf" value={endereco} onChange={l => this.setState({endereco: l.target.value})}/>
                                        </div>
                                    </div>

                                    <div class="accordion-content-item">
                                        <p>Data</p>
                                        <div className="flex-n">
                                            <label htmlFor="dateaf"><i class="fas fa-search"></i></label>
                                            <input type="text" id="dateaf" value={datae} onChange={l => this.setState({datae: l.target.value})}/>
                                        </div>
                                    </div>

                                    <div class="accordion-content-item">
                                        <p>Hora</p>
                                        <div className="flex-n">
                                            <label htmlFor="horaf"><i class="fas fa-search"></i></label>
                                            <input type="text" id="horaf" value={hora} onChange={l => this.setState({hora: l.target.value})}/>
                                        </div>
                                    </div>

                                    <div class="accordion-content-item">
                                        <p>Metragem</p>
                                        <div className="flex-n">
                                            <label htmlFor="metraf"><i class="fas fa-search"></i></label>
                                            <input type="text" id="metraf" value={metragem} onChange={l => this.setState({metragem: l.target.value})}/>
                                        </div>
                                    </div>

                                    <div class="accordion-content-item">
                                        <p>Status</p>
                                        <div className="flex-n">
                                            <label htmlFor="statusf"><i class="fas fa-search"></i></label>
                                            <select name="" value={statusselect} id="statusf" value={statusselect} onChange={l => this.setState({statusselect: l.target.value})}>
                                                <option value="" defaultChecked></option>
                                                <option value="Laudo">
                                                    Laudo
                                                </option>
                                                <option value="Controle de qualidade">Controle de qualidade</option>
                                                <option value="Aceito">Aceito</option>
                                                <option value="Aberto">Aberto</option>
                                                <option value="Execução">Execução</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                                        
                        </div>
                    <div className="content-table">
                        <table>
                            <thead>
                                <th>Endereço</th>
                                <th>Data</th>
                                <th>Hora</th>
                                <th>Metragem</th>
                                <th>Status</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="flex-n">
                                            <label htmlFor="end"><i class="fas fa-search"></i></label>
                                            <input type="text" id="end" value={endereco} onChange={l => this.setState({endereco: l.target.value})}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="flex-n">
                                            <label htmlFor="datea"><i class="fas fa-search"></i></label>
                                            <input type="text" id="datea" value={datae} onChange={l => this.setState({datae: l.target.value})}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="flex-n">
                                            <label htmlFor="hour"><i class="fas fa-search"></i></label>
                                            <input type="text" id="hour" value={hora} onChange={l => this.setState({hora: l.target.value})}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="flex-n">
                                            <label htmlFor="metra"><i class="fas fa-search"></i></label>
                                            <input type="text" id="metra" value={metragem} onChange={l => this.setState({metragem: l.target.value})}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="flex-n">
                                            <label htmlFor="status"><i class="fas fa-search"></i></label>
                                            <select name="" value={statusselect} id="status" value={statusselect} onChange={l => this.setState({statusselect: l.target.value})}>
                                                <option value="-1" defaultChecked></option>
                                    
                                                <option value="Aberto">Aberto</option>
                                                <option value="Aceito">Aceito</option>
                                                <option value="Execução">Execução</option>
                                                <option value="Controle de qualidade">Controle de qualidade</option>
                                                <option value="Laudo pronto">Laudo pronto</option>
                                                <option value="Laudo aprovado">Laudo aprovado</option>
                                                <option value="Laudo negado">Laudo negado</option>
                                            </select>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="content-box-subtitle">
                        <p>próximas vistorias</p>
                    </div>
                    <div class="accordions">
                        <div class="accordion-item">
                            <label>
                                <div className="colors-info">
                                    <div className="colors-info-item">
                                        <div className="status-dia status-aberto"></div>
                                        <p>Aberto</p>
                                    </div>
                                    <div className="colors-info-item">
                                        <div className="status-dia status-aprovado"></div>
                                        <p>Aceito</p>
                                    </div>
                                    <div className="colors-info-item">
                                        <div className="status-dia status-vistoria"></div>
                                        <p>Execução</p>
                                    </div>
                                    <div className="colors-info-item">
                                        <div className="status-dia status-andamento"></div>
                                        <p>Controle de qualidade</p>
                                    </div>
                                    <div className="colors-info-item">
                                        <div className="status-dia status-pronto"></div>
                                        <p>Laudo pronto</p>
                                    </div>
                                    <div className="colors-info-item">
                                        <div className="status-dia status-finalizado"></div>
                                        <p>Laudo aprovado</p>
                                    </div>
                                    <div className="colors-info-item">
                                        <div className="status-dia status-negado"></div>
                                        <p>Laudo negado</p>
                                    </div>
                                </div>
                            </label>
                        </div> 
                        {
                            this.filtrar(vistorias).map(e => (
                                e.status != "Controle de qualidade" && !e.status.includes('Laudo') ? (
                                    <div class="accordion-item">
                                        <button class="accordion" onClick={k => {this.openAccordion('accordionC-' + e.id)}}>
                                            <div className="flex-n">

                                                <div className={`status-dia ${status[e.status][1]}`}></div>
                                                <p>
                                                    {
                                                        this.filterImovel(e.imovel_id, imovel).map(i=>(
                                                            `${i.rua_imovel}, ${i.numero_imovel}`
                                                        ))
                                                    }
                                                </p>
                                            </div>
                                            <i class="fas fa-sort-down"></i>
                                        </button>

                                        {/* <input type="radio" name="accordion" />
                                        <label htmlFor={'accordion-' + e.id}>
                                            <p>
                                            </p>
                                            <i class="fas fa-sort-down"></i>
                                        </label> */}
                                        <div class="accordion-content" id={'accordionC-' + e.id} >
                                            <div class="accordion-content-item">
                                                <p>Endereço</p>
                                                <p>
                                                    {
                                                        this.filterImovel(e.imovel_id, imovel).map(i=>(
                                                            `${i.rua_imovel}, ${i.numero_imovel}, ${i.complemento} - ${i.cidade}, ${i.estado}`
                                                        ))
                                                    }
                                                </p>
                                            </div>
                                            <div class="accordion-content-item">
                                                <p>Metragem</p>
                                                <p>{this.filterImovel(e.imovel_id, imovel)[0].metragem}m²</p>
                                            </div>
                                            <div class="accordion-content-item">
                                                <p>Data</p>
                                                <p>{this.formatDia(e.data_agendada)}</p>
                                            </div>
                                            <div class="accordion-content-item">
                                                <p>Horarío</p>
                                                <p>{this.formatHora(e.hora_agendada)}</p>
                                            </div>
                                            <h4>Retirada da chave</h4>
                                            <div class="accordion-content-item">
                                                <p>Endereço</p>
                                                <p>{e.endereco_retirada}</p>
                                            </div>
                                            <div class="accordion-content-item">
                                                <p>Contato</p>
                                                <p>{e.contato_retirada}</p>
                                            </div>

                                            {
                                                e.status == "Aberto" ? (
                                                    <>
                                                        <div class="accordion-content-item">
                                                            <button className="choosebtns" onClick={p => {this.accpetvis(e.id)}}>Aceitar</button>
                                                            <button className="choosebtns negative" onClick={p => {this.refusevis(e.id)}}>Recusar</button>
                                                        </div>
                                                    </>
                                                ) : (null)
                                            }
                                            {
                                                e.status == "Aceito" ? (
                                                    <div class="accordion-content-item">
                                                        <a target="_blanck" href={`${linkest}/form/${e.HASH}`}>
                                                            <button className="choosebtns accepted">Iniciar</button>
                                                        </a>
                                                    </div>
                                                ) : (null)
                                            }
                                        </div>
                                    </div> 
                                ) : (null)
                            ))
                        }     

                    </div>
                    <div class="content-box-subtitle">
                        <p>vistorias concluidas</p>
                    </div>
                    <div class="accordions">
                        {
                            this.filtrar(vistorias).map(e => (
                                <div>
                                    {

                                        e.status == "Controle de qualidade" ? (
                                            <div class="accordion-item">
                                                <button class="accordion" onClick={k => {this.openAccordion('accordionC-' + e.id)}}>
                                                    <div className="flex-n">

                                                        <div className={`status-dia ${status[e.status][1]}`}></div>
                                                        <p>
                                                            {
                                                                this.filterImovel(e.imovel_id, imovel).map(i=>(
                                                                    `${i.rua_imovel}, ${i.numero_imovel}`
                                                                ))
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="flex-n">
                                                        <Link to={`/admin/perguntas/${e.HASH}`}><i class="far fa-edit"></i></Link>
                                                        <i class="fas fa-sort-down"></i>
                                                    </div>
                                                </button>
                                                {/* <input type="radio" name="accordion-concluido" id={'accordion-concluido-' + e.id} />
                                                <label htmlFor={'accordion-concluido-' + e.id} class="accordion-edit">
                                                    <p>
                                                        <div className={`status-dia ${status[e.status][1]}`}></div>
                                                        {
                                                            this.filterImovel(e.imovel_id, imovel).map(i=>(
                                                                `${i.rua_imovel}, ${i.numero_imovel}`
                                                            ))
                                                        }
                                                    </p>
                                                    <div>
                                                        <Link to={`/admin/perguntas/${e.HASH}`}><i class="far fa-edit"></i></Link>
                                                        <i class="fas fa-sort-down"></i>
                                                    </div>
                                                </label> */}
                                                <div class="accordion-content" id={'accordionC-' + e.id}>
                                                    <div class="accordion-content-item">
                                                        <p>Endereço</p>
                                                        <p>
                                                            {
                                                                this.filterImovel(e.imovel_id, imovel).map(i=>(
                                                                    `${i.rua_imovel}, ${i.numero_imovel}, ${i.complemento} - ${i.cidade}, ${i.estado}`
                                                                ))
                                                            }
                                                        </p>
                                                    </div>
                                                    <div class="accordion-content-item">
                                                        <p>Metragem</p>
                                                        <p>{this.filterImovel(e.imovel_id, imovel)[0].metragem}m²</p>
                                                    </div>
                                                    <div class="accordion-content-item">
                                                        <p>Data</p>
                                                        <p>{this.formatDia(e.data_agendada)}</p>
                                                    </div>
                                                    <div class="accordion-content-item">
                                                        <p>Horarío</p>
                                                        <p>{this.formatHora(e.hora_agendada)}</p>
                                                    </div>
                                                    <h4>Retirada da chave</h4>
                                                    <div class="accordion-content-item">
                                                        <p>Endereço</p>
                                                        <p>{e.endereco_retirada}</p>
                                                    </div>
                                                    <div class="accordion-content-item">
                                                        <p>Contato</p>
                                                        <p>{e.contato_retirada}</p>
                                                    </div>
                                                </div>
                                            </div> 
                                        
                                        ) : (null)
                                    }
                                    {

                                            e.status.includes('Laudo') ? (
                                            <div class="accordion-item">
                                                {/* <input type="radio" name="accordion-concluido" id={'accordion-concluido-' + e.id} />
                                                <label htmlFor={'accordion-concluido-' + e.id} class="accordion-conc">
                                                    <p>
                                                        <div className={`status-dia ${status[e.status][1]}`}></div>
                                                        {
                                                            this.filterImovel(e.imovel_id, imovel).map(i=>(
                                                                `${i.rua_imovel}, ${i.numero_imovel}`
                                                            ))
                                                        }
                                                    </p>
                                                <div><p>REVISADO</p><i class="fas fa-sort-down"></i></div></label> */}
                                                <button class="accordion" onClick={k => {this.openAccordion('accordionC-' + e.id)}}>
                                                    <div className="flex-n">

                                                        <div className={`status-dia ${status[e.status][1]}`}></div>
                                                        <p>
                                                            {
                                                                this.filterImovel(e.imovel_id, imovel).map(i=>(
                                                                    `${i.rua_imovel}, ${i.numero_imovel}`
                                                                ))
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="flex-n"><p>REVISADO</p><i class="fas fa-sort-down"></i></div>
                                                </button>
                                                <div class="accordion-content" id={'accordionC-' + e.id}>
                                                    <div class="accordion-content-item">
                                                        <p>Endereço</p>
                                                        <p>
                                                            {
                                                                this.filterImovel(e.imovel_id, imovel).map(i=>(
                                                                    `${i.rua_imovel}, ${i.numero_imovel}, ${i.complemento} - ${i.cidade}, ${i.estado}`
                                                                ))
                                                            }
                                                        </p>
                                                    </div>
                                                    <div class="accordion-content-item">
                                                        <p>Metragem</p>
                                                        <p>{this.filterImovel(e.imovel_id, imovel)[0].metragem}m²</p>
                                                    </div>
                                                    <div class="accordion-content-item">
                                                        <p>Data</p>
                                                        <p>{this.formatDia(e.data_agendada)}</p>
                                                    </div>
                                                    <div class="accordion-content-item">
                                                        <p>Horarío</p>
                                                        <p>{this.formatHora(e.hora_agendada)}</p>
                                                    </div>
                                                    <h4>Retirada da chave</h4>
                                                    <div class="accordion-content-item">
                                                        <p>Endereço</p>
                                                        <p>{e.endereco_retirada}</p>
                                                    </div>
                                                    <div class="accordion-content-item">
                                                        <p>Contato</p>
                                                        <p>{e.contato_retirada}</p>
                                                    </div>
                                                </div>
                                            </div> 
                                        ) : (
                                            null
                                        )
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>	
            </>
        )
    }
}

export default VistoriaPage