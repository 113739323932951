import React from 'react';
import { Link } from 'react-router-dom'

class MenuComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            icon: 'fas fa-bars',
            menu: 'content-menu hide',
            backmenu: 'overlay hide'

        }
    }

    openmenu = (e) => {
        const {menu} = this.state;
        let self = this;

        if (menu == "content-menu hide"){
            this.setState({icon: "fas fa-times", backmenu: 'overlay'})
            setTimeout(() => {
                self.setState({backmenu: 'overlay colored', menu: 'content-menu show'})
                
            }, 30);
            return
        }
        if (e.target.classList.contains('overlay')) {
            
            this.setState({icon: "fas fa-bars", menu: 'content-menu hide', backmenu: 'overlay'})
            setTimeout(() => {
                self.setState({icon: "fas fa-bars", backmenu: 'overlay hide'})
                
            }, 310);
        }
    }

    logout = (e) => {
        localStorage.clear()
        this.props.history.push('/')
    }

    render() {
        const {icon, menu, backmenu} = this.state;
        return (
            <>
                <link rel="stylesheet" href={require('../assets/css/Base/menu.css')}/>

                <div className="content-header">
                    <img src={require('../assets/img/logo.png')} />
                    <i className={icon} id="menu-icon" onClick={this.openmenu}></i>
                </div>
                <div className={backmenu} onClick={e => {this.openmenu(e)}}>
                    <div className={menu} id="menu">
                        <Link to="/admin/perfil" className="aNormal">
                            <div className="header">
                                <img src={require('../assets/img/logo.png')} />
                            </div>
                            <div className="perfil">
                                <div className="perfil-foto"></div>
                                <p>{localStorage.getItem('nomevisto')}</p>
                            </div>
                        </Link>
                        <div className="menu-itens">
                            <div className="menu-item">
                                <i className="fas fa-home"></i>
                                <Link to='/admin'>Inicial</Link>
                            </div>
                            <div className="menu-item">
                                <i className="fas fa-search-location"></i>
                                <Link to='/admin/vistorias'>Vistorias</Link>

                            </div>
                            <div className="menu-item">
                                <i className="far fa-calendar-alt"></i>
                                <Link to='/admin/agenda'>Agenda</Link>
                            </div>
                            <div className="menu-item">
                                <i className="fas fa-dollar-sign"></i>
                                <Link to='/admin/financeiro'>Financeiro</Link>
                            </div>
                            <div className="menu-item">
                                <i className="fab fa-telegram-plane"></i>
                                <Link to='/admin/telegram'>Telegram</Link>
                            </div>
                            <div className="menu-item" onClick={this.logout}>
                                <i className="fas fa-sign-out-alt"></i>
                                <a href="/">Sair</a>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default MenuComponent;