import React from 'react';

class TelegramPage extends React.Component{
    render(){
        return (
            <>
                <div className="telecurso">
                    <h1>Passo a passo</h1>
                    <br />

                    <p className="center">
                        Ao fazer download do app Telegram e criar sua conta, para começar a receber as notifições das vistorias atráves do app basta seguir o passo a passo a baixo:
                    </p>
                    <br />

                    <br />

                    <div className="teleimgs">
                        <div className="floattel">
                            <img src={require("../assets/img/p1.png")} alt=""/>
                            <div className="information">
                                <span>1°</span> Abra o aplicativo e clique no icone "pesquisar" na área superior direita
                            </div>
                        </div>
                        <div className="floattel">
                            <div className="information">
                                <span>2°</span> Pesquise por @Vistoriador e clique no item em seguida
                            </div>
                            <img src={require("../assets/img/p2.png")} alt=""/>
                        </div>
                        <div className="floattel">
                            <img src={require("../assets/img/p3.png")} alt=""/>
                            <div className="information">
                                <span>3°</span> Abra o chat e aperte em iniciar
                            </div>
                        </div>
                        <div className="floattel">
                            <div className="information">
                                <span>4°</span> Pronto! agora você vai receber todas as notifições em seu celular.
                            </div>
                            <img src={require("../assets/img/p4.png")} alt=""/>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default TelegramPage